import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	loadingIsVisible: true,
	ga4: true,
};

const uiSlice = createSlice({
	name: "ui",
	initialState,
	reducers: {
		setLoading(state, action) {
			state.loadingIsVisible = action.payload;
		},
		setGa4(state, action) {
			state.ga4 = action.payload;
		},
	},
});

export const uiActions = uiSlice.actions;

export default uiSlice;
