import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints
export const appApi = createApi({
	reducerPath: "appApi",
	baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_SERVER_URL}` }),
	endpoints: (builder) => ({
		getInfo: builder.query({
			query: () => `users/about-me`,
			transformResponse: (response) => {
				const cv = response.uploads.filter((u) => u.type === "curriculum");
				return {
					...response.user,
					cvPath: cv[0].path,
					cvOriginalname: cv[0].originalname,
				};
			},
		}),
		getCategories: builder.query({
			query: () => `categories`,
			transformResponse: (response) => response,
		}),
		getProjects: builder.query({
			query: () => `projects`,
			transformResponse: (response) => response,
		}),
		getProject: builder.query({
			query: (projectId) => `projects/${projectId}`,
			transformResponse: (response) => {
				const { project, uploads } = response;
				const featuredImage = uploads.filter(
					(u) => u.type === "featuredImage"
				)[0];
				return { ...project, featuredImage };
			},
		}),
		getCookiesPolicy: builder.mutation({
			query: (body) => ({
				url: `contents/cookie-policy`,
				method: "POST",
				body,
			}),
		}),
		getPrivacyPolicy: builder.mutation({
			query: (body) => ({
				url: `contents/privacy-policy`,
				method: "POST",
				body,
			}),
		}),
	}),
});

export const {
	useGetInfoQuery,
	useGetProjectQuery,
	useGetCategoriesQuery,
	useGetProjectsQuery,
	useGetCookiesPolicyMutation,
	useGetPrivacyPolicyMutation,
} = appApi;
