import "./Loading.scss";

const Loading = () => {
	return (
		<div id="loaderContainer">
			<span className="loader"></span>
		</div>
	);
};

export default Loading;
