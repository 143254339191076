import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { appApi } from "./app-service";
import uiSlice from "./ui-slice";
import projectSlice from "./project-slice";

const store = configureStore({
	reducer: {
		ui: uiSlice.reducer,
		project: projectSlice.reducer,
		[appApi.reducerPath]: appApi.reducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(appApi.middleware),
	devTools: process.env.REACT_APP_ENV !== "production",
});
setupListeners(store.dispatch);
export default store;
