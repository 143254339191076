import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	category: 0,
	categories: [],
	projects: [],
	filteredProjects: [],
	singleProject: null,
	limit: 9,
};

const projectSlice = createSlice({
	name: "project",
	initialState,
	reducers: {
		setCategories(state, action) {
			state.categories = action.payload;
		},
		setCategory(state, action) {
			state.category = action.payload;
			state.filteredProjects =
				action.payload === 0
					? (state.filteredProjects = state.projects)
					: (state.filteredProjects = state.projects.filter(
							(item) => item.categoryId === action.payload
					  ));
		},
		setProjects(state, action) {
			state.projects = action.payload;
		},
		setSingleProject(state, action) {
			state.singleProject = action.payload;
		},
		setFilteredProjects(state, action) {
			state.filteredProjects = action.payload;
		},
		setLimit(state, action) {
			state.limit = action.payload;
		},
	},
});

export const projectActions = projectSlice.actions;
export default projectSlice;
